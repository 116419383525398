<template>
  <div>
    <v-app-bar color="primary" dark>
      <v-app-bar-nav-icon
        v-if="!navButtons"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-img src="@/assets/alumex-logo.svg" contain height="30" />
      </v-toolbar-title>
      <v-spacer> </v-spacer>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-avatar>
              <img :src="require('@/assets/png/user.png')" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>Loyalty Member</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-img
                :src="require('../../assets/png/gold.png')"
                contain
                max-width="20"
              ></v-img>
            </v-list-item-action>
          </v-list-item>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/my-profile">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import AuthService from '../../services/auth-service';
import { mapGetters } from 'vuex';
export default {
  components: {},
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    logout() {
      AuthService.logout();
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    navButtons() {
      return ['login', 'register', 'forgot-password'].includes(
        this.$route.name
      );
    },
  },
};
</script>
