import store from '@/store';
import httpService from './http-service';
import endpoints from '../utilities/endpoints';

export default {
  getDistricts() {
    return httpService.get(endpoints.common.districts);
  },
  getExecutives() {
    return httpService.get(endpoints.common.executives);
  },
  register(payload) {
    return httpService.post(endpoints.auth.register, payload);
  },
  otpVerify(payload) {
    return httpService
      .post(endpoints.auth.verify_otp, payload)
      .then((response) => {
        if (response.data.token) {
          store.commit('auth/SET_USER', response.data.user);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          window.location.replace('/');
        }
      });
  },
  login(payload) {
    return httpService.post(endpoints.auth.login, payload);
  },

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return httpService.get(endpoints.auth.logout).then(() => {
      store.commit('auth/SET_USER', null);
      window.location.replace('/login');
    });
  },
  getUser() {
    return httpService.get('auth/userInfo').then((response) => {
      if (response.data) {
        store.commit('auth/SET_USER', response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      }
    });
  },
  forgotPassword(payload) {
    return httpService.post('auth/forgot-password', payload);
  },
  resetPassword(payload) {
    return httpService.post('auth/reset-password', payload);
  },
  updateProfile(uuid, payload) {
    return httpService.put('profile/' + uuid, payload);
  },
};
