<template>
  <v-app>
    <NavDrawerAppBar />
    <!-- <v-overlay :value="overlay" small z-index="500">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <v-overlay absolute :value="overlay" z-index="500">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-if="message"
      :value="message"
      :color="message ? message.color : ''"
      multi-line
      @input="handleSnackbarClose"
    >
      <v-icon
        :color="message && message.type == 'success' ? 'primary' : 'error'"
        >{{
          message && message.type == 'success'
            ? 'mdi-check-circle'
            : 'mdi-close-circle'
        }}
      </v-icon>
      <span class="caption ml-2">{{ message ? message.message : '' }}</span>

      <template v-slot:action="{ attrs }">
        <v-icon @click="closeMessage" v-bind="attrs">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <v-main class="secondary">
      <div class="mb-16">
        <router-view transition="scale-transition" />
      </div>
    </v-main>
    <v-footer
      v-bind="localAttrs"
      dark
      padless
      color="primary"
      class="footer-radius"
    >
      <v-col class="text-center text--white" cols="12">
        <div class="text-caption">
          © Alumex PLC - All Right Reserved. Concept & Design by HBSI
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import NavDrawerAppBar from '@/components/common/NavigationDrawerAppBar.vue';

export default {
  name: 'App',

  components: { NavDrawerAppBar },
  data: () => ({
    drawer: false,
    group: null,
    timeout: 2000,
    snackbar: true,
  }),

  computed: {
    overlay() {
      return this.$store.state.common.pendingRequestsCount > 0;
    },
    message() {
      return this.$store.state.common.notification;
    },
    locale() {
      return this.$store.state.common.locale;
    },
    localAttrs() {
      const attrs = {};
      attrs.absolute = true;

      return attrs;
    },
  },
  methods: {
    closeMessage() {
      this.$store.commit('common/REMOVE_NOTIFICATION');
    },
    handleSnackbarClose(input) {
      if (!input) {
        this.$store.commit('common/REMOVE_NOTIFICATION');
      }
    },
  },
};
</script>
