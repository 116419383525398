export default [
  {
    path: '/',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/Home.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth login',
      publicPage: false,
    },
  },
  {
    path: '/my-profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/Profile.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth login',
      publicPage: false,
    },
  },
  {
    path: '/redeem-points',
    name: 'redeemPoints',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/RedeemPoints.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth login',
      publicPage: false,
    },
  },
  {
    path: '/submit-complaint',
    name: 'submitComplaint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/SubmitComplaint.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth login',
      publicPage: false,
    },
  },
  {
    path: '/transactions',
    name: 'viewTransactions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/ViewTransactions.vue'
      );
    },
    meta: {
      // permission: "index_users",
      layout: 'full',
      class: 'page auth login',
      publicPage: false,
    },
  },
];
