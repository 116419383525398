export default {
  auth: {
    login: '/member/login',
    register: '/member/register',
    verify_otp: '/member/verify-otp',
    logout: '/member/logout',
    uploadDocuments: 'member/upload-documents',
  },
  user: {
    getUserData: 'member/get-user',
    transactionHistory: 'member/get-transactions',
  },
  dashboard: {
    redeemRequest: 'member/redeem-request',
    submitComplaint: 'member/submit-complaint',
  },
  common: {
    districts: 'common/districts',
    executives: 'common/executives',
  },
};
