// import store from '@/store';
// import notificationsUtility from '@/utilities/notifications-utility';
import Vue from 'vue';
import VueRouter from 'vue-router';
import authModule from './auth-module';
import dashboardModule from './dashbaord';
import PageNotFound from '../views/Error404.vue';
// import i18n from '@/plugins/i18n';

Vue.use(VueRouter);

const routes = [
  ...authModule,
  ...dashboardModule,
  { path: '*', component: PageNotFound },
  // {
  //   path: '/',
  //   name: 'welcome',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(
  //       /* webpackChunkName: "about" */ '../views/dashboard/Welcome.vue'
  //     );
  //   },
  //   meta: {
  //     publicPage: false,
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('token');

  if (!to.meta.publicPage) {
    if (!isLoggedIn) {
      if (from.name != 'login') {
        return next({ name: 'login' });
      }
      return next({ name: 'login' });
    }
  } else {
    if (
      isLoggedIn &&
      ['login', 'register', 'forgot-password'].includes(to.name)
    ) {
      window.location.replace('/');
    }
  }
  return next();
});

export default router;
